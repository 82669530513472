import {
  Button,
  Circle,
  HStack,
  Modal,
  ModalContent,
  ModalOverlay,
  Spacer,
  Text,
} from '@chakra-ui/react';
// import { PDFViewer } from "@laxmimanogna/code-quick-components"
import React, { useRef, useState } from 'react';
import './modal.css';
import { CloseIcon, OpenIcon } from '../../constants/IconData';
import AppColors from '../../constants/AppColors';
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';

const fileTypeMap = {
  'application/CDFV2': 'application/msword',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
    'application/vnd.ms-excel',
  'application/zip':
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
};

const FileViewer = ({ isOpen, onClose, currentFile }) => {
  const fileName = currentFile?.name.split('_').join(' ');

  const renderFile = () => {
    // const extension = currentFile?.name?.split('.')[1]

    const docs = [
      {
        uri: currentFile?.audited_chart_preview_url,
        fileType: fileTypeMap[currentFile?.audited_chart_mime_type] ?? currentFile?.audited_chart_mime_type,
        fileName: ''
      },
      {
        uri: currentFile?.preview_url,
        fileType: fileTypeMap[currentFile?.mime_type] ?? currentFile?.mime_type,
        fileName: ''
      }
    ].filter(doc => doc.uri);

    return (
      <>
        <DocViewer
          config={{ pdfZoom: { defaultZoom: 1.1, zoomJump: 0.2 } }}
          pluginRenderers={DocViewerRenderers}
          documents={docs}
          style={{ minHeight: 500 }}
        />
      </>
    );
  };

  return (
    <>
      <Modal
        size={'xl'}
        onClose={onClose}
        isOpen={isOpen}
        scrollBehavior={'outside'}
      >
        <ModalOverlay bg="#001A41" style={{ opacity: 0.8 }} />
        <ModalContent
          style={{ background: 'inherit', boxShadow: 'none', maxWidth: '45%' }}
        >
          <HStack mb={2}>
            <Text mt={2} color={'white'}>
              {fileName}
            </Text>
            <Spacer />
            {currentFile?.mime_type === 'application/pdf' ? (
              <Circle
                size="30px"
                bg={AppColors.white}
                color={AppColors.gray}
                onClick={() => {
                  window.open(currentFile?.preview_url, '_blank');
                }}
                style={{ cursor: 'pointer' }}
              >
                <OpenIcon />
              </Circle>
            ) : null}
            <CloseIcon
              style={{ cursor: 'pointer', height: '30px', width: '30px' }}
              onClick={onClose}
            />
          </HStack>
          {renderFile()}
        </ModalContent>
      </Modal>
    </>
  );
};
export default FileViewer;
